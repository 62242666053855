import { Injectable } from '@angular/core';

import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class QuoteGetService {
    constructor(
        private api: ApiService
    ) {
    }

    /**
     * get
     * @param params 
     * @param type 
     */
    get(hash: string): Observable<any> {
        return this.api.get('quote/' + hash);
    }
}