import { Injectable } from '@angular/core';

import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class PayService {
    constructor(
        private api: ApiService
    ) {
    }

    /**
     * get
     * @param params 
     * @param type 
     */
    get(contract: string, date: string) {
        return this.api.get('pay/' + contract + '/' + date);
    }
}